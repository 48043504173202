import React from 'react';
import { useStatePersist } from 'use-state-persist';

import { Link } from 'gatsby';

import ScreenReader from '../components/ScreenReader';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const SmartFeedJobCard = ({
  job,
  apiKey,
  viewBySelection,
  siteConfig,
  useGroupVacsLink,
  trackEvent,
}) => {
  const { t } = useI18nContext();
  const [savedJobs, setSavedJobs] = useStatePersist('@savedJobs_' + apiKey, []);

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'unSaveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'saveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    // dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = '2-digit';
    dateFormatOptions.day = '2-digit';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  return (
    <div
      className={`py-2 col-12 column ${
        viewBySelection === 'grid' ? 'col-md-6' : ''
      }`}
    >
      <div className="job-card card" id={new Buffer(job.Id).toString('base64')}>
        <div className="job-card-details">
          <div className="d-flex justify-content-between">
            <div className="job-card-header">
              <Link
                to={
                  siteConfig.companyCareersSite
                    ? `${
                        useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }vacancy/` +
                      new Buffer(job.Id).toString('base64') +
                      '/' +
                      slugify(job.JobTitle)
                    : `/${apiKey}${
                        useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }vacancy/` +
                      new Buffer(job.Id).toString('base64') +
                      '/' +
                      slugify(job.JobTitle)
                }
                className="job-card-title"
              >
                {job.JobTitle}
              </Link>
              {/* <div className="job-card-location">{job.Location}</div> */}
            </div>
            <div className="job-card-actions">
              <div
                title={t('vacancies.job-card.save-for-later')}
                className="job-card-savejob"
                onClick={() => {
                  if (
                    savedJobs.filter((e) => e == job.Id.toString()).length > 0
                  ) {
                    unSaveJob(job.Id);
                  } else {
                    saveJob(job.Id);
                  }
                }}
              >
                <button className="btn btn-link">
                  {savedJobs.filter((e) => e == job.Id.toString()).length >
                  0 ? (
                    <span className="fas fa-heart"></span>
                  ) : (
                    <span className="far fa-heart"></span>
                  )}
                </button>
              </div>
              <div className="icon-divider"></div>
              <div className="job-card-reader">
                <ScreenReader
                  text={`${job.JobTitle}, 
                  ${t('vacancies.job-card.screenreader-para1')} ${
                    job.JobType
                  }, ${t('vacancies.job-card.screenreader-para2')} ${
                    job.SalaryFrom
                  } ${t('vacancies.job-card.screenreader-para3')} ${
                    job.SalaryTo
                  }
                  ${t(
                    'vacancies.job-card.screenreader-para4'
                  )} ${FormatDateTime(job.ExpiryDate)}.
                  ${t('vacancies.job-card.screenreader-para5')} ${
                    job.JobCategory
                  }
                  ${t('vacancies.job-card.screenreader-para6')}`}
                />
              </div>
            </div>
          </div>
          <div className="job-card-meta row">
            <div className="job-card-meta-item">
              <div className="job-card-info">
                <span className="job-card-icons fas fa-clock"></span>
                <div className="job-card-info-output">
                  <span>{t('vacancies.job-card.type')}</span>
                  <span
                    className="job-card-info-recieved-text"
                    title={
                      job.AdvertLanguage === 'cy-GB'
                        ? (job.customFieldJson[0]?.Item2 || job.JobType) +
                          ' - ' +
                          (job.customFieldJson[1]?.Item2 || job.JobTypeTime)
                        : job.JobType + ' - ' + job.JobTypeTime
                    }
                  >
                    {' '}
                    <p>
                      {job.AdvertLanguage === 'cy-GB'
                        ? (job.customFieldJson[0]?.Item2 || job.JobType) +
                          ' - ' +
                          (job.customFieldJson[1]?.Item2 || job.JobTypeTime)
                        : job.JobType + ' - ' + job.JobTypeTime}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="job-card-meta-item">
              <div className="job-card-info">
                <span className="job-card-icons fas fa-pound-sign"></span>
                <div className="job-card-info-output">
                  <span>{t('vacancies.job-card.salary')}:</span>
                  <span
                    className="job-card-info-recieved-text"
                    title={calculateSalary(
                      job.Currency,
                      job.SalaryFrom,
                      job.SalaryTo,
                      job.SalaryType
                    )}
                  >
                    <p>
                      {calculateSalary(
                        job.Currency,
                        job.SalaryFrom,
                        job.SalaryTo,
                        job.SalaryType
                      )}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="job-card-meta-item">
              <div className="job-card-info">
                <span className="job-card-icons fas fa-calendar"></span>
                <div className="job-card-info-output">
                  <span>{t('vacancies.job-card.closing-date')}:</span>
                  <span
                    className="job-card-info-recieved-text"
                    title={FormatDateTime(job.ExpiryDate)}
                  >
                    <p>{FormatDateTime(job.ExpiryDate)}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="job-card-meta-item">
              <div className="job-card-info">
                <span className="job-card-icons fas fa-folder"></span>
                <div className="job-card-info-output">
                  <span>{t('vacancies.job-card.category')}:</span>
                  <span
                    className="job-card-info-recieved-text"
                    title={job.JobCategory}
                  >
                    <p>{job.JobCategory}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="job-card-buttons-wrap">
            <Link
              className="job-card-button btn--primary w-100"
              to={
                siteConfig.companyCareersSite
                  ? `${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }vacancy/` +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle)
                  : `/${apiKey}${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }vacancy/` +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle)
              }
            >
              {t('vacancies.job-card.more-details')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobCard;
