import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';
import 'url-search-params-polyfill';

import fetchJsonp from 'fetch-jsonp';
import SmartFeedJobCard from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobCard';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';
import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

require('es6-promise').polyfill();

let prevCount = 0;
let currentCount = 0;
let keywordTime = null;

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

const SmartFeedList = ({
  siteConfig,
  apiKey = '',
  group = false,
  smartfeedCompanyData = {},
  trackEvent,
  useGroupVacsLink,
  vacancyLoading = true,
  setVacancyLoading = setVacancyLoadingBackup,
}) => {
  const id = 1;
  const [vacancy, setVacancy] = useState([]);
  const [vacancyTitle, setVacancyTitle] = useState('Loading vacancies...');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('is-language')) || false);

  const {
    i18nState: { lang },
    t,
    changeLang,
  } = useI18nContext();

  // Filter States
  const {
    filtersState: { keywords, filters },
    loading,
    setLoading,
    resetFilters,
  } = useFiltersContext();

  const [sortBySelection, setSortBySelection] = useStatePersist(
    '@sortBy',
    'postingdate'
  );
  const [sortByDirSelection, setSortByDirSelection] = useStatePersist(
    '@sortDir',
    'desc'
  );

  const [viewBySelection, setViewBySelection] = useStatePersist(
    '@sortView',
    'grid'
  );

  const [vacsCount, setVacsCount] = useState(0);
  const [loadMoreText, setLoadMoreText] = useState(false);

  let groupOrIdParam = 'id';

  if (group === true) {
    groupOrIdParam = 'group';
  }

  useEffect(() => {
    clearTimeout(keywordTime);

    keywordTime = setTimeout(() => {
      fetchVacancies();
    }, 500);
  }, [keywords]);

  useEffect(() => {
    fetchVacancies();
  }, [filters, lang]);

  const fetchVacancies = async (name, loadMore) => {
    setLoadMoreText('Loading.....');

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
      setLoading(true);
    }

    if (name === 'reset') {
      resetFilters();
    }

    function getAdvertData(jobs, prevCount, currentCount, query) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint +
              '/CareerPage/GetItem?id=' +
              id +
              '&' +
              query,
            {
              timeout: 15000,
            }
          ).then(function (response) {
            return response.json();
          });
        })
      );
    }

    try {
      const postingTypeValue =
        name === 'reset' ? 'careers' : filters.postingtype || 'careers';

      const query = name === 'reset' ? '' : '&' + buildQuery();

      fetchJsonp(
        siteConfig.sfAPIEndpoint +
          '/CareerPage/GetResultList?' +
          groupOrIdParam +
          '=' +
          apiKey +
          '&languageIdentifier=' +
          lang +
          '&sortby=' +
          sortBySelection +
          '&sortdir=' +
          sortByDirSelection +
          '&postingtype=' +
          postingTypeValue +
          '&keywords=' +
          keywords +
          query,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(async function (jsonList) {
          prevCount = currentCount;
          currentCount = currentCount + 12;

          const query = 'customFieldList=job_x0020_type|job_x0020_time';
          await getAdvertData(jsonList, prevCount, currentCount, query).then(
            function (data) {
              if (loadMore) {
                setVacancy(vacancy.concat(data));
              } else {
                setVacancy(data);
              }

              if (jsonList.length === 0) {
                setVacancyTitle(t('messages.couldnt-find-vacancies'));
              } else {
                let count = currentCount;
                if (currentCount > jsonList.length) count = jsonList.length;
                setVacancyTitle(
                  t('vacancies.showing-vacancies', {
                    vars: { count, vacancies: jsonList.length },
                  })
                );
              }

              setInitialLoading(false);

              setLoading(false);
              setVacancyLoading(false);

              setLoadMoreText(t('vacancies.load-more'));
              setVacsCount(jsonList.length);
            }
          );
        });
    } catch (error) {
      setLoading(false);
      setVacancyLoading(false);
      console.error(error.message, 'error');
    }
  };

  const loadMore = () => {
    fetchVacancies(undefined, true);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'loadMore',
        eventLabel: 'Loaded more vacancies',
      });
    }
  };

  const resetVacs = () => {
    fetchVacancies('reset', false);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'resetVacs',
        eventLabel: 'Reset the filters',
      });
    }
  };

  const searchVacs = function searchVacs() {
    fetchVacancies('search', false);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'searchVacs',
        eventLabel: 'Triggered a search',
      });
    }
  };

  useEffect(() => {
    if (!isInitialLoad) {
      searchVacs();
    }
  }, [sortBySelection, sortByDirSelection]);

  const buildQuery = function buildQuery() {
    let query = [];

    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        );
      }
    }

    return query.join('&');
  };

  useEffect(() => {
    if (isInitialLoad) {
      if (trackEvent) {
        trackEvent({
          eventCategory: 'SmartFeedListComponent',
          eventAction: 'initalised',
          eventLabel: apiKey,
        });
      }

      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search);
        var entries = urlParams.entries();
      }
      let temp = {};
      for (let pair of entries) {
        temp[pair[0]] = pair[1];
      }

      const timeOut = setTimeout(() => {
        fetchVacancies();

        setIsInitialLoad(false);
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [isInitialLoad]);

  return !vacancyLoading &&
    smartfeedCompanyData &&
    smartfeedCompanyData.CompanyName ? (
    <section className="main-vacs-wrap">
      <div className="row">
        <div className="col-12">
          <div className="jobs-count-tab d-flex justify-content-between align-items-center">
            {/* <h3 className="vacancies-landing__header__heading text-nowrap">
              {vacancyTitle}
            </h3> */}
            <h1 className="vacancies-landing__header__heading text-nowrap">
         {t('vacancies.navigation.current-vacancies')}
            </h1>
            <p>
              {t('sentences.homepage-hero')}{' '}
              <br></br><br></br>
              {t('sentences.sub-homepage-hero')}{' '}
              <a
                className="smartfeed-list-mail-info"
                href="mailto:HR@gcs.ac.uk"
              >
                HR@gcs.ac.uk
              </a>
            </p>
            <Popup
              open={!language}
              modal
              nested
            >
              {(close) => (
                <div className="modall">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <div className="header">
                    {/* <div>Choose your Language </div>  */}
                   
              <img
                loading="lazy"
                src="https://www.gcs.ac.uk/sites/default/files/gcs-large.png"
                className="logo"
          
              />
            
                     </div>
                  {/* <div className="content">
                  Choose your Language. Your selection will be recorded and the whole website will appear in the chosen language
                  <br/>
                  Dewiswch eich Iaith. Bydd eich dewis yn cael ei recordio a bydd y wefan gyfan yn ymddangos yn yr iaith ddewisol
                  </div> */}
                  <div className="actions">
                   <div className="button-container">
                   <button
                      className="button job-card-button btn--primary"
                      onClick={() => {
                        changeLang('cy-GB')
                        close();
                        localStorage.setItem('is-language', JSON.stringify(true));
                        language = true
                      }}
                    >
                      Cymraeg
                    </button>
                   </div>
                   <div  className="button-container">
                <button
                      className="button job-card-button btn--primary"
                      onClick={() => {
                        changeLang('en-GB')
                        close();
                        localStorage.setItem('is-language', JSON.stringify(true));
                        language = true
                      }}
                    >
                      English
                    </button>
                </div>
                  </div>
                </div>
              )}
            </Popup>
            {siteConfig.displayVacanciesSortOptions && (
              <div className="vacancies-landing__header__sortbox">
                <div className="ml-auto">
                  <div className="sortbox__select_wrap">
                    <label htmlFor="vf-vacs-sort-by" className="sortbox__label">
                      {t('vacancies.sort-by.sort-by')}
                    </label>
                    <select
                      id="vf-vacs-sort-by"
                      className="VF-form-control onchange-search form-control"
                      onChange={(e) => setSortBySelection(e.target.value)}
                      placeholder={t('vacancies.sort-by.placeholder')}
                      title={t('vacancies.sort-by.title')}
                      selected={sortBySelection}
                    >
                      <option value="expirydate">
                        {t('vacancies.sort-by.closing-date')}
                      </option>
                      <option value="jobtitle">
                        {t('vacancies.sort-by.job-title')}
                      </option>
                      <option value="location">
                        {t('vacancies.sort-by.location')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row vf-careers-site">
        {!vacancyLoading &&
          (loading ? (
            t('messages.loading-vacancies')
          ) : (
            <>
              {vacancy &&
              vacancy !== [] &&
              vacancy !== '[]' &&
              !initialLoading ? (
                <>
                  {vacancy && vacancy.length > 0 ? (
                    vacancy.map((job, i) => (
                      <SmartFeedJobCard
                        job={job}
                        key={i}
                        viewBySelection={viewBySelection}
                        apiKey={apiKey}
                        siteConfig={siteConfig}
                        useGroupVacsLink={useGroupVacsLink}
                        trackEvent={trackEvent}
                      />
                    ))
                  ) : (
                    <div className="job-card card job-card-no-results">
                      <div className="job-card-details">
                        <div className="no-results">
                          {t('messages.no-results')}
                        </div>
                        <button
                          id="reset_vacs"
                          className="nav-action-sm"
                          onClick={() => resetVacs()}
                        >
                          {t('vacancies.reset')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="no-vacancies">
                  {t('messages.loading-vacancies')}
                </div>
              )}

              <div className="load-more-wrap col-12">
                {currentCount < vacsCount && (
                  <a
                    className="nav-action-sm"
                    role="button"
                    onClick={() => loadMore()}
                  >
                    {loadMoreText}
                  </a>
                )}
              </div>
            </>
          ))}
      </div>
    </section>
  ) : (
    <div className="page-loading-block">
      <div className="loading-panel">
        <div className="loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span className="loading-text">
          {t('messages.please-wait-while-load')}
        </span>
      </div>
    </div>
  );
};

export default SmartFeedList;
