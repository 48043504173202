import React, { useState } from "react";

import { useI18nContext } from "@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n";

const SmartFeedNavigation = ({ active, onNavChange }) => {
  const { t } = useI18nContext();

  return (
    <div className="vf-navigation btn-group flex-wrap flex-md-nowrap w-100">
      <button
        className={`btn border ${
          active === "Careers" ? "btn--primary" : "btn-light"
        } w-100`}
        onClick={() => {
          onNavChange("Careers");
        }}
      >
        {t("vacancies.navigation.current-vacancies")}
      </button>
      <button
        className={`btn border ${
          active === "Register" ? "btn--primary" : "btn-light"
        } w-100`}
        onClick={() => {
          onNavChange("Register");
        }}
      >
        {t("vacancies.navigation.register-your-interest")}
      </button>
      <button
        className={`btn border ${
          active === "Alerts" ? "btn--primary" : "btn-light"
        } w-100`}
        onClick={() => {
          onNavChange("Alerts");
        }}
      >
        {t("vacancies.navigation.subscribe-to-job-alerts")}
      </button>
    </div>
  );
};

export default SmartFeedNavigation;
